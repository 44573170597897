import { Component } from '@angular/core';

@Component({
  selector: 'pw-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss']
})
export class PricelistComponent {

}
