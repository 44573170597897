import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetSpecialitiesResponse } from './getspecialitiesresponse';
import { Speciality } from './speciality';
import { SpecialityWithPrices } from './specialitywithprices';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { SpecialityDoctorsComponent } from '../specialitydoctors/specialitydoctors.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'pw-consultation',
    templateUrl: './consultation.component.html',
    styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit {
    regeneroBaseUrl = '';

    getSpecialitiesUrl = '/regenero/get-specialities';

    specialities?: Speciality[];

    showExamination = false;

    chosenSpecialityId = 0;

    hasSpecialityChosen = false;

    isExtraSmall: Observable<BreakpointState>;

    constructor(
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog,
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
    }

    ngOnInit(): void {
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                this.specialities = response.specialityList;

            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    showExaminationElement() {
        this.specialities!.forEach((speciality) => {
            speciality.hasSpecialityChosen = false;
            speciality.isVisible = false;
        })
        this.showExamination = !this.showExamination;
    }

    chooseOneSpeciality(speciality: SpecialityWithPrices) {
        this.chosenSpecialityId = speciality.speciality?.id!;
        speciality.hasSpecialityChosen = !speciality.hasSpecialityChosen;
        speciality.isVisible = !speciality.isVisible;
    }

    //TODO now it is not a popup, but has to be decided!!!
    openDoctor(specialityId: number) {
        const popupDialog = this._matDialog.open(SpecialityDoctorsComponent, {
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%',
            data: specialityId,
            backdropClass: 'backgroundblur',
            hasBackdrop: true

        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }
}
