import { Component, ElementRef, Inject } from '@angular/core';
import {
    ApiRequestService,
    LanguageService,
    TranslatableComponent,
    TranslationService,
    TranslationStore,
    MAT_ICON,
    TOAST_STATE,
    HttpError,
    ToastmessageService,
    IntroductionComponent
} from '@prekog/pw-common';
import { GetTranslationUseCase } from './usecases/translation-usecase/read-translation-usecase';
import { Router } from '@angular/router';
import { GetTranslationRequest } from './usecases/translation-usecase/read-translation-request';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetDoctorResponse } from './getdoctorresponse';
import { NavigationMenuItem } from './navigation-menu';
import { Doctor } from './doctor';
import { GetSpecialitiesResponse } from './consultation/getspecialitiesresponse';
import { Speciality } from './consultation/speciality';
import { GetDoctorsByRequest } from './specialitydoctors/getdoctorsbyrequest';
import { GetDoctorsByResponse } from './specialitydoctors/getdoctorsbyresponse';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends TranslatableComponent {
    title = 'Regenero Egészségház';

    cookieValue = '';

    facebookImgUrl = '';

    facebookUrl = 'https://www.facebook.com/profile.php?id=100063541752114';

    loading = false;

    logoUrl = '';

    nameOfTheSite = 'Regenero Egészségház';

    production = false;

    downloadFileUrl = '';

    aszfUrl = '';

    dataManagamentUrl = 'http://localhost:8088/file/downloadFile/20240627T085717908963614';

    simplePayLogo = '';

    colorOfNavBar = 'grey';

    logoSize = '';

    getDoctorsForSubMenuUrl = '/regenero/get-doctors';

    regeneroBaseUrl = '';

    doctors: Doctor[] = [];

    navigationMenu: NavigationMenuItem[] = [];

    isSubMenuFullScreen = true;

    getSpecialitiesUrl = '/regenero/get-specialities';

    specialities?: Speciality[];

    getDoctorsBySpecialityUrl = '/regenero/get-doctors-by-speciality';

    isRowView = false;

    colorOfMobileSidenav = "lightorange"

    constructor(
        private _translateService: TranslationService,
        private _http: HttpClient,
        private _readTranslationfileUseCase: GetTranslationUseCase,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        private _languageService: LanguageService,
        @Inject('PRODUCTION') private _production: boolean,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private elem: ElementRef,
        private _toastMessageService: ToastmessageService
    ) {
        super(_translateService, _http);
        this.production = _production;
        this.downloadFileUrl = _downloadFileUrl;
        this.facebookImgUrl = this.downloadFileUrl + '/facebooklog';
        this.logoUrl = this.downloadFileUrl + '/20240626T101258865160117';
        this.logoSize = '200px';
        this.elem.nativeElement.style.setProperty('--logo-size', this.logoSize);
        this.regeneroBaseUrl = _regeneroBaseUrl;
    }

    override readTranslations(http: HttpClient, language: string): Observable<TranslationStore> {
        const request: GetTranslationRequest = { url: './assets/i18n/' + language + '.json' };
        this._readTranslationfileUseCase.execute(request) as unknown as Observable<TranslationStore>;
        return this._http.get<TranslationStore>('./assets/i18n/' + language + '.json');
    }

    override onInit(): void {
        this.aszfUrl = this.downloadFileUrl + '/aszf';
        this.dataManagamentUrl = this.downloadFileUrl + '/20240627T085717908963614';
        this.settingUpNavigationMenu();

    }


    settingUpSubmenu() {
        let submenuList: NavigationMenuItem[] = [];
        this._apiRequestService
            .sendRequest<{}, GetDoctorResponse>(this.regeneroBaseUrl, true, {}, true, this.getDoctorsForSubMenuUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetDoctorResponse) => {
                this.doctors = response.doctors as Doctor[];
                this.getSpecialities();
                this.navigationMenu.forEach((navigationElement: NavigationMenuItem) => {
                    if (navigationElement.id === '') {
                        const index = this.navigationMenu.indexOf(navigationElement);
                        this.navigationMenu.splice(index, 1);
                    }
                });

            });
    }

    getSpecialities() {
        let submenuList: NavigationMenuItem[] = [];
        let subsubmenuList: NavigationMenuItem[] = [];
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                this.specialities = response.specialityList!;
                this.specialities.forEach((speciality: Speciality) => {
                    if (speciality.isSpeciality === false) {
                        const index = this.specialities!.indexOf(speciality);
                        this.specialities!.splice(index, 1);
                    }
                });
                this.navigationMenu.forEach((navigationElement: NavigationMenuItem) => {
                    if (navigationElement.translationKey === 'Szakrendelés') {
                        this.specialities!.forEach((speciality) => {
                            let submenu: NavigationMenuItem = {
                                translationKey: speciality.name,
                                id: '/consultation' + '/' + speciality.id,
                                isHidden: false,
                                isDefault: false,
                                hasDynamicSubMenu: true
                            };
                            if (!speciality.description) {
                                submenu.id = '';
                            }
                            submenuList.push(submenu);
                            console.log(submenu)

                            //get doctors
                            const getdoctorsbyrequest = {
                                specialityId: speciality.id
                            };
                            this._apiRequestService
                                .sendRequest<GetDoctorsByRequest, GetDoctorsByResponse>(
                                    this.regeneroBaseUrl,
                                    true,
                                    getdoctorsbyrequest,
                                    true,
                                    this.getDoctorsBySpecialityUrl
                                )
                                .pipe(
                                    catchError((error: HttpErrorResponse) => {
                                        return throwError(() => {
                                            const httpError: HttpError = error.error as HttpError;

                                            this._toastMessageService.displayToastMessage(
                                                TOAST_STATE.error,
                                                'HttpError hiba!',
                                                MAT_ICON.error
                                            );
                                            this.dismissError();

                                            this.router.navigateByUrl('/');
                                        });
                                    })
                                )
                                .subscribe((response: GetDoctorsByResponse) => {
                                    this.doctors = response.doctorList!;
                                    this.doctors.forEach((doctor) => {
                                        let subsubmenu: NavigationMenuItem = {
                                            translationKey: doctor.name,
                                            id: '/doctor' + '/' + doctor.id!,
                                            isHidden: false,
                                            isDefault: false,
                                            hasDynamicSubMenu: false
                                        };
                                        subsubmenuList.push(subsubmenu);
                                    });
                                    submenu.staticSubMenus = [...subsubmenuList];
                                    subsubmenuList = [];
                                });
                        });
                        navigationElement.staticSubMenus = [...submenuList];
                        console.log(navigationElement);
                    }
                });
            });
    }

    settingUpNavigationMenu() {
        this._http.get('./assets/configurations/navigation-menu.json').subscribe((response) => {
            this.navigationMenu = response as NavigationMenuItem[];
            this.settingUpSubmenu();
        });
    }

    getDoctorsForMenu() {
        this._apiRequestService
            .sendRequest<{}, GetDoctorResponse>(this.regeneroBaseUrl, true, {}, true, this.getDoctorsForSubMenuUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetDoctorResponse) => {
                this.doctors = response.doctors as Doctor[];
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    getParts(sum: number, length: number) {
        var left = Math.ceil(sum / length),
            right = Math.floor(sum / length),
            first = (sum - right * length) / right;

        return Array.from({ length }, (_, i) => (i < first ? left : right));
    }

    populate(sum: number, n: number, interval: number): Array<number> {
        var fill = Math.floor(sum / n);
        if (fill * interval < sum) fill = Math.ceil(sum / interval);
        else if (fill * (interval - 1) > sum) fill = Math.ceil(sum / (sum % interval ? interval - 1 : interval));
        var array = new Array<number>(interval);
        array.fill(fill);
        array[interval - 1] = sum - (interval - 1) * fill;
        return array;
    }

    onOutletLoaded(component: IntroductionComponent) {
        component.isRowView = this.isRowView as boolean;
        component.googleMapIframeSrc = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBSYe-gDScK77G0sZGbV3Ab_FFKizyP_xo&q=Regenero';
    }
}



