import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { IntroductionComponent } from '@prekog/pw-common';
import { ConsultationComponent } from './consultation/consultation.component';
import { DoctorComponent } from './doctor/doctor.component';
import { HealthfundComponent } from './healthfund/healthfund.component';
import { SpecialityDoctorsComponent } from './specialitydoctors/specialitydoctors.component';
import { PricelistComponent } from './pricelist/pricelist.component';
import { SpecialitydescriptionComponent } from './specialitydescription/specialitydescription.component';
import { ConsultinghoursComponent } from './consultinghours/consultinghours.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path:'introduction', component: IntroductionComponent },
    { path:'consultation', component: ConsultationComponent},
    { path:'specialitydoctors/:id', component: SpecialityDoctorsComponent},
    { path:'healthfund', component: HealthfundComponent},
    { path:'doctor/:routerLink', component: DoctorComponent, pathMatch:'full'},
    { path:'pricelist', component: PricelistComponent},
    { path:'consultation/:id', component: SpecialitydescriptionComponent},
    { path:'consultatinghours', component: ConsultinghoursComponent}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
