import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { GetSpecialityDescriptionRequest } from './getspecialitydescriptionrequest';
import { GetSpecialityDescriptionResponse } from './getspecialitydescriptionresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Speciality } from '../consultation/speciality';

@Component({
  selector: 'app-specialitydescription',
  templateUrl: './specialitydescription.component.html',
  styleUrls: ['./specialitydescription.component.scss']
})
export class SpecialitydescriptionComponent implements OnInit {

    regeneroBaseUrl = '';

    id = 0;

    speciality:Speciality = {};

    getSpecialityDescriptionUrl = '/regenero/get-speciality-description';

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,

    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;
    }

    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.id = +routerLink;

        const getSpecialityDescriptionRequest:GetSpecialityDescriptionRequest = {
            specialityId:this.id
        }

        this._apiRequestService
        .sendRequest<GetSpecialityDescriptionRequest, GetSpecialityDescriptionResponse>(this.regeneroBaseUrl, true, getSpecialityDescriptionRequest, true, this.getSpecialityDescriptionUrl)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                    this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                    this.dismissError();

                    this._router.navigateByUrl('/');
                });
            })
        )
        .subscribe((response: GetSpecialityDescriptionResponse) => {
            this.speciality = response.speciality!;



        });
    }


    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

}
